@import "_variables.less";

eb-carousel {
  display: block;
}

.carousel__frame {
  scroll-snap-type: x mandatory;
  user-select: none;
}

.carousel__items {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  gap: 1rem;
}

.eb-carousel-item {
  scroll-snap-align: start;
}

.carousel:not(:hover) .carousel-controls {
  display: none;
}

.carousel-control__prev {
  top: 25%;
  left: -18px;
}

.carousel-control__next {
  top: 25%;
  right: -18px;
}

@media only screen and (max-width: @screen-sm-max) {
  .carousel--full-width-mobile {
    left: calc(var(--main-container-padding) * -1);
    width: 100vw;

    .carousel__frame {
      scroll-padding: 1rem;
    }

    .carousel__items--padding {
      padding: 0 1rem;
    }
  }
}